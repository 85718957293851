
























import { Component, Vue } from 'vue-property-decorator';
import Privacy from '@/components/consents/Privacy.vue';

@Component({
  components: {
    Privacy
  }
})
export default class P0902 extends Vue {
  mounted() {
    //スタイル調整（別ページの規約と共通化するために、装飾用のクラスを別途DOM操作により付与）
    let headlineElems = document.querySelectorAll('.terms_headline');
    if (headlineElems) {
      headlineElems.forEach(function(elem) {
        elem.classList.add('u-ttl');
        elem.classList.remove('terms_headline');
      });
    }

    let headlineElemsChild = document.querySelectorAll('.terms_headline-child');
    if (headlineElemsChild) {
      headlineElemsChild.forEach(function(elem) {
        elem.classList.add('u-ttlBar');
        elem.classList.remove('u-ttl');
        elem.classList.remove('terms_headline-child');
      });
    }

    let titleElems = document.querySelectorAll('.terms_title');
    if (titleElems) {
      titleElems.forEach(function(elem) {
        elem.classList.add('u-ttlSimple');
        elem.classList.add('u-ttlSimple-center');
        elem.classList.add('u-ttlSimple-mbS');
        elem.classList.remove('terms_title');
      });
    }
  }
}
